<template>
  <div class="content-page ship_address">
    <!-- 地址列表 -->
    <div class="p-2 mb-5">
      <div class="title d-flex">
        <h4>
          <i style="color: #0aa1eb" class="ri-map-pin-line"></i> Shipping address
        </h4>
        <div class="col text-right">
          <button class="btn_open" @click="openModal">+ Add New</button>
        </div>
      </div>
      <!-- 地址 -->
      <!-- <p class="mt-2">
        <input type="radio" checked />13 / F, Central Building, Hong Kong (Florence
        guan) +852 2455 4488 <strong class="pl-4">Default Address</strong>
      </p>
      <p>
        <input type="radio" />13 / F, Central Building, Hong Kong (Florence
        guan) +852 2455 4488
      </p>
      <p>
        <input type="radio" />13 / F, Central Building, Hong Kong (Florence
        guan) +852 2455 4488
      </p>
      <button class="btn">More</button> -->

      <div class="mt-3">
        <div class="row mt-2 my_row" :class="{'default':active==1}">
          <div class="col-12 col-md-6">
            <input type="radio" checked name="radio" @click="active=1" class="cursor-pointer" /> 238-240 Lockhart Road,
            Wan
            Chai, Hong
            Kong(Consignee:Lemowen)
          </div>
          <div class="col-12 col-md-6">
            <div class="row">
              <div class="col">
                <span>18162424313</span><strong class="ml-2"> Default address</strong>
              </div>
              <div class="col-2 text-right">
                <router-link to="#"><u @click="openModal">Edit</u></router-link>
                <router-link style="color: #ef5959" class="ml-3" to="#"><u>Delete</u></router-link>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-2 my_row" :class="{'default':active==2}">
          <div class="col-12 col-md-6">
            <input type="radio" name="radio" @click="active=2" class="cursor-pointer" /> 238-240 Lockhart Road, Wan
            Chai,
            Hong
            Kong(Consignee:Lemowen)
          </div>
          <div class="col-12 col-md-6">
            <div class="row">
              <div class="col">
                <span>18162424313</span>
              </div>
              <div class="col-2 text-right">
                <router-link to="#"><u @click="openModal">Edit</u></router-link>
                <router-link style="color: #ef5959" class="ml-3" to="#"><u>Delete</u></router-link>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-2 my_row" :class="{'default':active==3}">
          <div class="col-12 col-md-6">
            <input type="radio" name="radio" @click="active=3" class="cursor-pointer" /> 238-240 Lockhart Road, Wan
            Chai,
            Hong
            Kong(Consignee:Lemowen)
          </div>
          <div class="col-12 col-md-6">
            <div class="row">
              <div class="col">
                <span>18162424313</span>
              </div>
              <div class="col-2 text-right">
                <router-link to="#"><u @click="openModal">Edit</u></router-link>
                <router-link style="color: #ef5959" class="ml-3" to="#"><u>Delete</u></router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 弹框 -->
    <b-modal id="addressModal" centered title="Edit Shipping address" hide-footer>
      <div class="add_address">
        <div class="row">
          <div class="col-6">
            <h6>Consignee</h6>
            <input class="int" type="text" />
          </div>
          <div class="col-6">
            <h6>Phone number</h6>
            <input class="int" type="text" />
          </div>
        </div>
        <div>
          <h6>Postcode</h6>
          <input class="int w-50" type="text" />
        </div>
        <div>
          <h6>Shipping address</h6>
          <input type="text" class="int" />
        </div>
        <div class="mb-3">
          <h6>Detailed address</h6>
          <input type="text" class="int-area">
        </div>
        <div class="mb-5 switch">
          <el-switch v-model="value1" active-text="Set as default address"></el-switch>
        </div>
        <hr />
        <div class="text-right">
          <button class="btn_open">Save</button>
        </div>
      </div>
    </b-modal>
    <!-- 添加地址 -->
    <!-- <div class="p-2">
      <h4 class="title">
        <i style="color: #0aa1eb" class="ri-add-fill"></i> Shipping address
      </h4>
      <div class="row p-2">
        <div class="col-3">Address information</div>
        <div class="col-9">
          <input class="w-75" type="text" placeholder="Please select the area">
        </div>
      </div>
      <div class="row p-2">
        <div class="col-3">Detailed Address</div>
        <div class="col-9">
          <textarea class="w-75" type="text" placeholder="Please enter the detailed address"></textarea>
        </div>
      </div>
      <div class="row p-2">
        <div class="col-3">Consignee Name</div>
        <div class="col-9">
          <input class="w-75" type="text" placeholder="Please enter Consignee Name">
        </div>
      </div>
      <div class="row p-2">
        <div class="col-3">Phone number</div>
        <div class="col-9">
          <input class="w-75" type="text" placeholder="Please enter Phone number">
        </div>
      </div>
      <div class="offset-3 p-2"><input type="checkbox"> Set to the default address</div>
      <div class="offset-3 p-2"><button class="btn btn1">Save</button></div>
    </div> -->
  </div>
</template>

<script>
  export default {
    data() {
      return {
        radio: "1",
        active: 1,
        value1: ''
      };
    },
    methods: {
      openModal() {
        this.$bvModal.show('addressModal')
      }
    },
  };
</script>

<style lang="scss" scoped>
  .ship_address {
    background-color: #fff;
  }

  .title {
    border-bottom: 1px solid #eee;
    padding-bottom: 15px;
    align-items: center;
  }

  .btn {
    width: 122px;
    height: 45px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    color: #333;
  }

  .default {
    background: #f5fbff;
    border: 1px solid #50b5ff;
  }

  .my_row {
    padding: 8px 0;
    box-sizing: border-box;
  }

  .btn_open {
    width: 110px;
    height: 35px;
    color: #50b5ff;
    background: #f5fbff;
    border: 1px solid #50b5ff;
    border-radius: 5px;
  }

  .btn1 {
    background: #0aa1eb;
    color: #fff;
    border: 1px solid #0aa1eb;
  }

  input {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
  }

  textarea {
    border: 1px solid rgba(0, 0, 0, 0.2);
  }

  ::v-deep .modal-dialog.modal-md.modal-dialog-centered {
    max-width: 800px !important;
  }

  .switch {
    ::v-deep span {
      color: #3F414D;
      font-weight: normal;
    }
  }

  .add_address {
    color: #3F414D;

    h6 {
      margin: 15px 0;
    }

    .int {
      width: 100%;
      height: 45px;
      border: 1px solid #f1f1f1;
      border-radius: 5px;
    }

    .int-area {
      width: 100%;
      height: 85px;
      border: 1px solid #f1f1f1;
      border-radius: 5px;
    }
  }
</style>